export const eventEmitter = {
  events: {},

  on: function (eventName, handler) {
    const eventId = `${eventName}-${this.id}`
    if (!this.events[eventId]) {
      this.events[eventId] = [];
    }
    this.events[eventId].push(handler);
  },

  off: function (eventName, handler) {
    const eventId = `${eventName}-${this.id}`
    if (this.events[eventId]) {
      this.events[eventId] = this.events[eventId].filter(h => h !== handler);
    }
  },

  emit: function (eventName, data) {
    const eventId = `${eventName}-${this.id}`
    if (this.events[eventId]) {
      this.events[eventId].forEach(handler => {
        handler(data);
      });
    }
  }
};