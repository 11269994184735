<script setup>
import { computed, onMounted, watch, ref, h } from 'vue';
import { WIDGET_CARD, WIDGET_BUTTON, THEME_LIGHT, THEME_DARK } from '../constans';
import { toPlural } from '../helpers/pluralize';
import { eventEmitter } from '../helpers/events';
import IconError from '../components/icons/IconError.vue';
import logoLight from '../components/icons/logoLight.vue';
import logoDark from '../components/icons/logoDark.vue';
import IconSpiner from '../components/icons/IconSpiner.vue';
const DEFAULT_TEXT = 'Оплатить частями';

const props = defineProps({
  id: {
    type: String,
    default: 'frilly-widget'
  },
  apiToken: {
    type: String
  },
  token: {
    type: String
  },
  viewType: {
    type: String,
    default: 'responsive',
    validator: (val) => ['responsive', 'static'].includes(val)
  },
  config: {
    type: String
  },
  previewTotalAmount: {
    type: Number,
    default: 1000
  },
  previewTerm: {
    type: Number,
    default: 6
  },
  items: {
    type: String,
    default: '[]'
  },
  callbackUrlSuccess: {
    type: String,
    default: ''
  },
  callbackUrlError: {
    type: String,
    default: ''
  }
});

const widgetData = ref({
    type: WIDGET_CARD,
    amount: 0,
    config: {
      buttonText: DEFAULT_TEXT,
      buttonColor: '#78ED6F',
      bgColor: '#019CC2',
      textColor: '#fff',
      textSecondaryColor: '#bababa',
      buttonTextColor: '#444',
      buttonSecondaryColor: '#f5f5f5',
      buttonSecondaryTextColor: '#000',
      logoTheme: null
    }
  }),
  isLoading = ref(false),
  widgetError = ref(false);

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const kebabToCamelCase = (str) => {
  return str.replace(/-([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
};

const widgetId = computed(() => kebabToCamelCase(props.id));

const items = ref([]),
  isWidgetInit = ref(false),
  errorMessage = ref(null);

const showErrorMessage = (text, timeOut = 3000) => {
  errorMessage.value = text;
  widgetError.value = true;
  setTimeout(() => {
    errorMessage.value = '';
    widgetError.value = false;
  }, timeOut);
};

const onSubmit = (useRedirect = true) => {
  return new Promise((resolve, reject) => {
    isLoading.value = true;
    const url = `${import.meta.env.VITE_API_URL}/public/loans/create`;
    postData(url, {
      token: props.token,
      items: items.value.map((product) => {
        return {
          ...product,
          ...{
            productId: null
          }
        };
      })
    })
      .then((result) => {
        const existsEvents = Object.keys(window[widgetId.value].events).length > 0;
        if (result.success) {
          resolve(result?.loan);

          if (existsEvents) {
            useRedirect = false;
            window[widgetId.value].emit('onCreateLoan', { data: result?.loan });
            isLoading.value = false;
          }

          if (useRedirect && result?.loan?.link) {
            const callbackUrlSuccess = props.callbackUrlSuccess || widgetData.value?.callbackUrlSuccess,
              callbackUrlError = props.callbackUrlError || widgetData.value?.callbackUrlError;

            if (callbackUrlSuccess || callbackUrlError) {
              const urls = {
                ['callback-url-success']: props.callbackUrlSuccess || widgetData.value?.callbackUrlSuccess,
                ['callback-url-error']: props.callbackUrlError || widgetData.value?.callbackUrlError
              };

              const queryParams = new URLSearchParams(urls).toString();
              window.location.replace(`${result.loan.link}${queryParams ? '?' + queryParams : ''}`);
              return;
            }

            window.location.replace(`${result.loan.link}`);
          }
        } else {
          if (existsEvents) {
            window[widgetId.value].emit('onCreateLoanError', { data: result?.exception });
          }

          if (result?.exception?.message) {
            showErrorMessage(result.exception.message);
          }

          reject(result?.exception);
          isLoading.value = false;
        }
      })
      .catch((error) => {
        isLoading.value = false;
        reject(error);
      });
  });
};

const widgetType = computed(() => widgetData.value.config.type);

const amount = computed(() => widgetData.value.amount || 0);

const monthlyPayment = computed(() => {
  return currencyFormat((widgetData.value?.monthlyPayment || 0).toFixed(0));
});

const logoButton = () => {
  if (widgetData.value.config?.logoTheme === THEME_LIGHT) return h(logoLight);
  if (widgetData.value.config?.logoTheme === THEME_DARK) return h(logoDark);
  return null;
};

const buttonText = computed(() => widgetData.value.config.buttonText);

const currencyFormat = (value) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    useGrouping: true,
    maximumFractionDigits: 0
  }).format(value);
};

const durationText = computed(() => {
  return `${widgetData.value.term} ${toPlural(widgetData.value.term, [`месяц`, `месяца`, `месяцев`])}`;
});

const postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${props.apiToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  });
  return response.json();
};

function debounce(func, delay) {
  let timerId;

  return function (...args) {
    const context = this;

    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

const getWidgetData = async (items = []) => {
  const url = `${import.meta.env.VITE_API_URL}/public/widgets/get`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${props.apiToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      token: props.token,
      items
    })
  });
  return response.json();
};

const loadFromBackend = async (items = []) => {
  try {
    isLoading.value = true;
    const { widget } = await getWidgetData(items);
    widgetData.value = widget;
    widgetData.value.config.buttonText = widgetData.value.config.buttonText || DEFAULT_TEXT;
    isWidgetInit.value = true;
  } catch (error) {
    widgetError.value = true;
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  const font = document.createElement('link');
  font.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700';
  font.rel = 'stylesheet';
  document.head.appendChild(font);
});

const addItem = ({ quantity, productCategoryId = 1, productName, productSku, productPrice }) => {
  const newItem = { id: new Date().getTime(), quantity, productCategoryId, productName, productSku, productPrice };
  items.value.push(newItem);
  return newItem;
};

const editItem = (productSku, { quantity, productCategoryId, productName, productPrice }) => {
  const foundItem = items.value.find((item) => item.productSku === productSku);
  if (foundItem) {
    foundItem.quantity = quantity;
    foundItem.productCategoryId = productCategoryId;
    foundItem.productName = productName;
    foundItem.productPrice = productPrice;
  }

  return foundItem;
};

const getItems = () => {
  return items.value;
};

const deleteItem = (productSku) => {
  items.value = items.value.filter((item) => item.productSku !== productSku);
};

const clearItems = () => {
  items.value = [];
};

watch(
  () => props.items,
  (value) => {
    items.value = value;
    try {
      items.value = JSON.parse(value.replace(/'/g, '"'));
    } catch (error) {
      items.value = [];
    }
  },
  {
    immediate: true
  }
);

// только для превью виджета, в кабинете мерчанта
watch(
  () => props.config,
  (value) => {
    if (value) {
      isWidgetInit.value = true;
      const config = JSON.parse(value);
      widgetData.value = { totalAmount: props.previewTotalAmount, term: props.previewTerm, config };
    }
  },
  {
    immediate: true
  }
);

watch(
  items,
  debounce((value) => {
    if (props.config) return;

    loadFromBackend(value || []);
  }, 300),
  {
    deep: true,
    immediate: true
  }
);

window[widgetId.value] = {
  id: widgetId.value,
  crateLoan: onSubmit,
  addItem,
  editItem,
  getItems,
  deleteItem,
  clearItems,
  ...eventEmitter
};
</script>

<template>
  <div class="widget-wrapper">
    <div v-if="isWidgetInit" class="frilly-widget" :class="[viewType]">
      <div v-if="widgetType === WIDGET_BUTTON">
        <button class="card_button--button hovered--light" :class="{ 'is-loading': isLoading }" @click="onSubmit(true)">
          <span class="card_button--content"> {{ buttonText }} </span>
          <div class="logo">
            <logo-button />
          </div>
          <div v-if="isLoading" class="loader">
            <icon-spiner />
          </div>
        </button>
      </div>

      <div class="card" :class="[widgetType]" v-else>
        <div class="card_price-value">
          <div class="card_price-amount">{{ currencyFormat(amount) }}</div>
          на {{ durationText }}
        </div>
        <div class="text-secondary card_installment-text">Без первого взноса</div>
        <div class="card_monthly-payment">
          {{ widgetType === WIDGET_CARD ? 'Платёж в месяц' : isMobile ? 'Платёж в месяц' : 'в месяц' }}
          <div class="card_monthly-payment--amount">{{ monthlyPayment }}</div>
        </div>

        <span class="card_button">
          <button
            class="card_button--button hovered--light"
            :class="{ 'is-loading': isLoading }"
            @click="onSubmit(true)"
          >
            <span class="card_button--content">{{ buttonText }}</span>
            <div class="logo">
              <logo-button />
            </div>
            <div v-if="isLoading" class="loader">
              <icon-spiner />
            </div>
          </button>
        </span>
      </div>
    </div>
    <div v-if="widgetError" class="widget-error"><icon-error /> {{ errorMessage || 'Ошибка в коде виджета' }}</div>
  </div>
</template>

<style scoped>
.widget-wrapper {
  position: relative;
}
.frilly-widget {
  box-sizing: border-box;
  font-family: Inter;
  container-name: wrapper;
  container-type: inline-size;
}
.text-secondary {
  color: v-bind('widgetData.config.textSecondaryColor');
}
.card_button--button {
  color: v-bind('widgetData.config.buttonTextColor');
  display: flex;
  align-items: center;
  gap: 0px 5px;
}
.card_button--button.is-loading {
  position: relative;
  pointer-events: none;
}

.card_button--button.is-loading .loader {
  display: flex;
  align-items: center;
  position: absolute;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.card_button--button.is-loading .card_button--content,
.card_button--button.is-loading .logo {
  opacity: 0;
}

.card {
  border-radius: 20px;
  padding: 15px;
  background: v-bind('widgetData.config.bgColor');
  color: v-bind('widgetData.config.textColor');
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  align-items: center;
  gap: 5px 24px;
}

.card.compactCard {
  grid-template-columns: 1fr auto;
}
.card.compactCard .card_price-value {
  display: none;
}
.card.compactCard .card_monthly-payment {
  grid-area: 1/1/1/1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  flex-direction: row-reverse;
  text-align: left;
  justify-content: flex-end;
}
.card.compactCard .card_monthly-payment--amount {
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
}
.card_button {
  grid-area: 1 / 3 / span 2 / 3;
}
button {
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: v-bind('widgetData.config.buttonColor');
  border: 0;
  outline: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  justify-self: start;
}
.card_installment-text {
  width: 160px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  justify-content: center;
  justify-self: start;
  align-self: start;
  gap: 10px;
  color: #019cc2;
  background: v-bind('widgetData.config.textColor');
  color: v-bind('widgetData.config.buttonTextColor');
  border-radius: 40px;
  grid-area: 2 / 1 / 2 / span 2;
}
.card_price-value {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}
.card_price-amount {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.card_monthly-payment {
  grid-area: 1 / 2 / span 2 / 2;
  font-size: 12px;
}
.card_monthly-payment--amount {
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: right;
}
.card_button--button {
  padding: 12px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 16px;
  height: 48px;
  margin: auto;
}
.widget-error {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #019cc2;
  border-radius: 20px;
  justify-content: center;
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
}
.frilly-widget.responsive {
  @supports (contain: layout) {
    @container wrapper (max-width: 580px) {
      .card {
        grid-template-columns: auto 1fr;
        gap: 15px 5px;
      }
      .card_price-value {
        grid-area: 1 / 1/ 1 / span 2;
        justify-content: center;
        font-size: 12px;
      }
      .card_installment-text {
        grid-area: 2 / 1/ 2 / span 2;
        width: 100%;
        padding: 10px 0;
        position: relative;
        margin-bottom: 10px;
      }
      .card_installment-text::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: calc(100% + 12px);
        background: #fafafa63;
      }
      .card_monthly-payment {
        grid-area: 3 / 1 / 3 / 1;
      }
      .card_button {
        grid-area: 3 / 2 / 3 / 2;
        justify-self: end;
      }
      .card_monthly-payment--amount {
        text-align: left;
      }
      .card_button--button {
        min-width: 160px;
      }
    }
  }

  @media (max-width: 580px) {
    .card {
      grid-template-columns: auto 1fr;
      gap: 15px 5px;
    }
    .card_price-value {
      grid-area: 1 / 1/ 1 / span 2;
      justify-content: center;
      font-size: 12px;
    }
    .card_installment-text {
      grid-area: 2 / 1/ 2 / span 2;
      width: 100%;
      padding: 10px 0;
      position: relative;
      margin-bottom: 10px;
    }
    .card_installment-text::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: calc(100% + 12px);
      background: #fafafa63;
    }
    .card_monthly-payment {
      grid-area: 3 / 1 / 3 / 1;
    }
    .card_button {
      grid-area: 3 / 2 / 3 / 2;
      justify-self: end;
    }
    .card_monthly-payment--amount {
      text-align: left;
    }
    .card_button--button {
      min-width: 160px;
    }
  }

  @supports (contain: layout) {
    @container wrapper (max-width: 580px) {
      .card.compactCard {
        grid-template-columns: auto 1fr;
        gap: 15px 5px;
      }
      .card.compactCard .card_price-value {
        grid-area: 1 / 1/ 1 / span 2;
        justify-content: center;
        font-size: 12px;
      }
      .card.compactCard .card_installment-text {
        display: none;
      }

      .card.compactCard .card_monthly-payment {
        grid-area: 1 / 1 / 1 / 1;
        flex-direction: column;
        align-items: flex-start;
      }
      .card.compactCard .card_button {
        grid-area: 1 / 2 / 1 / 2;
        justify-self: end;
      }
      .card.compactCard .card_button--button {
        min-width: 160px;
      }
    }
  }

  @media (max-width: 580px) {
    .card.compactCard {
      grid-template-columns: auto 1fr;
      gap: 15px 5px;
    }
    .card.compactCard .card_price-value {
      grid-area: 1 / 1/ 1 / span 2;
      justify-content: center;
      font-size: 12px;
    }
    .card.compactCard .card_installment-text {
      display: none;
    }

    .card.compactCard .card_monthly-payment {
      grid-area: 1 / 1 / 1 / 1;
      flex-direction: column;
      align-items: flex-start;
    }
    .card.compactCard .card_button {
      grid-area: 1 / 2 / 1 / 2;
      justify-self: end;
    }
    .card.compactCard .card_button--button {
      min-width: 160px;
    }
  }
}
.frilly-widget.static {
  width: 580px;
}
</style>
