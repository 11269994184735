/**
 * Склонение слов в зависимости от кол-ва
 * @param {Number|String} value
 * @param {String} one
 * @param {String} two
 * @param {String} five
 * @returns {string}
 */
export const toPlural = (value, [one, two, five]) => {
  let n = Math.abs(value);
  n %= 100;

  if (n >= 5 && n <= 20) {
    return `${five}`;
  }

  n %= 10;
  if (n === 1) {
    return `${one}`;
  }

  if (n >= 2 && n <= 4) {
    return `${two}`;
  }

  return `${five}`;
};
