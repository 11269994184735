<template>
  <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <circle cx="12.5" cy="12" r="12" fill="#EA4B47" />
      <path d="m8.5 8 8 8M16.5 8l-8 8" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
